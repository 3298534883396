import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  ru: {
    translation: {
      name_logo: "Электронный клинический фармаколог",
      cdss: "Система поддержки принятия врачебных решений",
      editing_disabled: "Редактирование запрещено",
      // "Welcome to React": "добро пожаловать в экф",
      patient_patients: "Пациенты",
      main_menu_exit: "Выход",
      main_menu_about: "О программе",
      patient_notSelected: "Пациент не выбран",
      main_search_tip: "Внесите лекарственные препараты с помощью строки поиска наверху или",
      main_parser_tip: "напишите их свободным текстом",
      main_parser_header: "Введите препараты",
      main_parser_tip2: "Можно указать несколько препаратов с дозировками, система попытается их распознать",
      main_parser_cancel: "Отмена",
      main_parser_parse: "Распознать",
      main_search_notFound: "Ничего не найдено",
      main_drugs_reset: "Сбросить",
      main_drugs_check: "Проверить",
      main_drugs_prescribe: "Назначить",
      main_drugs_write: "Выписать",

      patinet_card_passport_label: "Заполнить паспортные данные",
      patinet_card_name_patient: "Введите имя пациента",
      protocol: "Протокол",
      patinet_card_passport_edit: "Редактировать паспортные данные",
      patinet_card_collapse_passport: "Свернуть паспортные данные",
      new_patient: "Новый пациент",
      sex: "Пол",
      male: "Мужчина",
      female: "Женщина",
      growth: "Рост",
      weight: "Вес",
      body_mass_index: "ИМТ",
      body_surface_area: "ППТ",

      lastname_placeholder: "Иванов",
      firstname_placeholder: "Иван",
      patronymic_placeholder: "Иванович",
      date_placeholder: "ДД.ММ.ГГГГ",

      kg_m2: "кг/м²",
      m2: "м²",
      underlying_disease: "Основное заболевание",
      accompanying_illnesses: "Сопутствующие заболевания",
      gene_mutation: "Мутация гена",
      allele: "Аллель",
      add: "Добавить",
      genetic_passport: "Генетический паспорт",
      update_patient_genetic_passport: "Обновить генетический паспорт пациента",
      download_genetic_passport: "Загрузить генетический паспорт",
      allergy: "Аллергия",
      laboratory_data: "Лабораторные данные",
      pregnancy: "Беременность",
      trimester: "Триместр",

      week: "Неделя",
      car_driving: "Вождение авто",
      alcohol: "Алкоголь",
      lactation: "Кормление грудью",
      fill_out_protocol: "Заполните протокол и нажмите кнопку 'печать' в конце",
      close: "Закрыть",
      main_menu_settings: "Настройки",
      main_menu_profile: "Профиль",
      main_menu_clinics: "Клиники",

      not_allowed_rf: "Не разрешено на территории РФ",
      federal_list: "ЖНВЛП федеральный список",
      set_output_options: "Установите параметры вывода",
      save: "Сохранить",
      back: "Назад",
      next: "Далее",
      result: "Результат",
      apply: "Применить",
      instruction: "Инструкция",

      login: "Вход в систему",
      in_system: "в системе",
      confirm: "Подтвердить",
      registration: "Регистрация",
      restore: "Восстановить",
      forgot_your_password: "Забыли пароль",
      remember_me: "Запомнить меня",
      personal_key: "Персональный ключ",
      password: "Пароль",

      jump: "Перейти",
      go_to_login: "Перейдите на страницу авторизации.",
      you_already_registered: "Вы уже зарегистрированы в системе!",
      attention: "Внимание!",
      already_registered: "Уже зарегистрированы?",
      password_six_characters: "Пароль должен содержать не менее шести символов",
      password_confirmation: "Подтверждение пароля",
      clinic_selected: "Выбрана клиника",
      department: "Отделение",
      enter_name_clinic: "Введите наименование вашей клиники",
      last_name: "Фамилия",
      middle_name: "Отчество",
      first_name: "Имя",

      fill_passport_details: "Заполните необходимые паспортные данные чтобы сохранить нового пациента",
      new: "Новый",
      delete: "Удалить",
      patient: "Пациент",
      analogues: "Аналоги",
      no_analogues: "Нет аналогов",
      more: "Подробнее",

      //18.01

      age_enter_number: "Возраст (введите число)",
      age_date_birth: "Возраст/дата рождения",
      remove_from_solution: "Убрать из раствора",
      dosage: "Дозировка",
      date_birth: "Дата рождения",
      choose_one_or_more_options: "Выберите один или несколько вариантов",
      choose_one_options: "Выберите один из вариантов",
      select_one_or_more_options: "Выберите один или несколько вариантов",
      show: "Показать",
      name_patient: "ФИО пациента",
      name_doctor: "ФИО врача",

      council_minutes: "ПрОТОкол консилиума",
      writing_recipe: "Выписать рецепт",
      resuscitation_appointment_list: "Реанимационный лист назначений",
      stationary_appointment_list: "Стационарный лист назначений",
      outpatient_appointment_list: "Амбулаторный лист назначений",
      choose_form: "Выберите форму бланка",
      choose: "Выберите",
      signed: "Подписано",
      subscribe: "Подписать",
      print: "Печать",
      send: "Отправить",
      send_mail: "Отправить письмо",
      send_by_mail: "Отправить по E-mail",

      recipe_form: "Форма рецептурного бланка",
      recipe_state_time: "Время действия рецепта",
      comment_prescription: "Комментарий к рецепту",

      fill_numero: "Заполните поле!",

      print_recipe: "Печать рецепта",
      code_pharmacy: "Ваш код для аптеки",
      clinic: "Клиника",
      mail_patient: "E-mail пациента",
      create: "Создать",
      create_patient: "Создать пациента",
      doctor: "Врач",
      explanation_recipe: "Пояснения к рецепту",
      available_certificates: "доступные сертификаты",
      no_available_certificates: "Нет доступных сертификатов",
      no_available_certificates_text: "У вас нет доступных сертификатов",
      select_certificate_for_signature: "Выберите сертификат для подписи",

      no_information: "Нет информации",
      free_information: "Сводная информация",
      clinical_recommendations_for_diagnosis: "Клинические рекомендации по диагнозу",
      clinical_recommendations_GPT: "Клинические рекомендации ВОЗ",
      clinical_recommendations_MRF: "Клинреки Минздрава России",
      clinical_recommendations: "Клинические рекомендации",
      remove_from_list: "Убрать из списка",
      solution: "Раствор",
      drag_two_components_for_solution: "Перетащите сюда не менее двух составляющих для раствора",
      add_one_element: "Добавьте еще хотя бы один элемент",
      cancellation_prescription: "отменить изменения предписания",
      special_instructions: "Особые указания",
      suitable_for_solutions: "Подходит для раствора",

      mode_application: "Способ применения",
      dosage_form: "Лекарственная форма",
      choose_dosage_form_method: "Сначала выбрать лекарственную форму, потом способ применения",
      choose_method_dosage_form: "Сначала выбрать способ применения, потом лекарственную форму",
      once_day: "Раза в день",
      or: "или",

      kg_weight_day: "На кг веса в сутки",
      daily_dose: "Суточная доза",
      multiplicity_reception: "Кратность приема",
      kg_body_weight: "На кг массы тела",
      body_mass: "Масса тела",
      motherwise_dose: "Разовая доза",
      components_solution: "Составляющие раствора",

      //

      select: "Выбрать",
      first_specify_dosage_components_solution: "Сначала укажите дозировку для всех составляющих раствора!",

      speed_in_ml: "Скорость в мл",
      speed_in_drops: "Скорость в каплях",
      speed_introduction_by_kg: "Скорость введения на кг",
      speed_administration: "Скорость введения",
      duration_administration: "Длительность введения",

      home_accepts: "Начало приема",
      tomorrow: "завтра",
      end_reception: "Окончание приема",
      before_canceling: "До отмены",
      by_day_week: "По дням недели",
      single: "Однократно",
      scheduled: "По расписанию",

      terms_accepted: "Условия приема",
      all_selected_data: "Все выбранные данные, включая клинику, пациента и лист назначений",
      sheet_appointments_completely: "Лист назначений полностью",
      only_patient_data: "Только данные по пациенту, включая текущий лист назначений и анамнез",
      what_data_clean: "Какие данные вы хотите очистить?",

      can_cleaned: "Можно очистить:",
      clear_all: "Очистить все",
      clear_sheet_appointments: "Очистить сведение о фармакотерапии",
      clear_patient_data: "Очистить сведение о пациенте",
      clear: "Очистить",
      choose_clinic: "Выберите клинику",
      patient_data: "Данные пациента",
      sheet_appointments: "Лист назначений",
      continue: "Продолжить",
      relieve_solutions: "Сбросить растворы",
      full_name: "ФИО",
      latest_selected: "Последние выбранные",
      // enter_patient_name: "Введите имя пациента",

      time_receipt: "Время приема",
      x_every_day: "(Х раз в день)",
      quantity: "Количество",
      through_x_days: "(через Х дней)",
      gap: "Промежуток",
      x_days: "(Х дней)",
      period: "Период",
      every_x_days: "(каждые Х дней)",
      repeat: "Повтор",

      or_create_own: "Или создайте свой",
      according_special_purpose: "По специальному назначению",
      choose_vacation_frequency: "Выберите периодичность отпуска",

      other_period: "иной период",
      monthly: "ежемесячно",
      daily: "ежедневно",
      indicate_vacation_frequency: "Укажите другую периодичность отпуска",
      health_care_facility: "ЛПУ",
      time: "Время",

      code_category_code: "Код категории граждан",
      patient_medical_map_number: "Номер медицинской карты пациента",
      SNILS: "СНИЛС",
      percent_payment: "% Оплаты",
      municipal_budget: "Муниципальный бюджет",
      budget_subject_russian_federation: "Бюджет субъекта Российской Федерации",
      federal_budget: "Федеральный бюджет",
      source_financing: "Источник финансирования",
      manual_input_code: "ручной ввод кода",
      nonological_form_code: "Код нозологической формы (по МКБ)",
      choose_determine_nosological_form_code: "Выберите заболевание для определения кода нозологической формы (по МКБ)",
      polis_oms: "Полис ОМС",
      residence_address: "Адрес места жительства",

      interaction_level_enzymes: "Взаимодействие на уровне ферментов",
      pharmocheological_interaction: "Фармокологическое взаимодействие",
      jnvlp_regional_list: "ЖНВЛП региональный список",
      to: "до",
      by: "от",
      choose_days_week: "Выберите дни недели",

      //

      report_an_error: "Сообщить об ошибке", /// //// ///
      notify_about_error: "Сообщите об ошибке",
      enter_message_text: "Введите текст сообщения",
      to_report: "Сообщить",
      cancel: "Отменить",
      number_dose: "Кол-во доз",
      volume_solution: "Объем раствора",

      measurements: "ед. измерения",
      across: "через",
      in: "в",
      with_breaks: "с перерывами",
      everyone: "каждые",

      form_input: "Форма ввода",

      diplomas: "Дипломы",
      licenses: "Лицензии",

      minute: "минуту",
      minutes: "минуты",
      minut: "минут",

      hour: "час",
      o_clock: "часа",
      hours: "часов",

      day: "день",
      a_day: "дня",
      days: "дней",

      year: "год",
      of_the_year: "года",
      years: "лет",

      month: "месяц",
      a_month: "месяца",
      months: "месяцев",

      a_week: "неделя",
      weeks: "недели",
      week_min: "недель",
      week_time: "неделю",

      once: "раз",
      times: "раза",

      year_birth: "года рождения",
      birth: "рождения",
      birth_min: "р.",
      year_birth_min: "г.р.",

      greetings: "Здравствуйте",

      sm: "см",
      kg: "кг",
      mg: "мг",
      sut: "сут",
      ml: "мл",
      cap: "кап",

      ecp: "ЭКФ",
      already_have_code: "Уже есть код?",
      come_in: "Войти",
      set: "Задать",
      new_password: "новый пароль",
      confirmation_code: "Код подтверждения",
      re_code_confirmation: "повторный код подтверждения",
      // send: "выслать",
      code_invalid: "Код недействителен",
      code_for_changing_password_invalid: "Этот код для смены пароля недействителен.",
      change_password_must_send_new_confirmation_code:
        "Чтобы сменить пароль, необходимо повторно отправить письмо с новым кодом подтверждения.",
      enter_new_password: "Введите новый пароль",
      recommendations: "Рекомендации",

      confirmation_registration: "ПОДТВЕРЖДЕНИЕ РЕГИСТРАЦИИ",
      request_registration_code: "ЗАПРОС КОДА РЕГИСТРАЦИИ",
      password_reset: "СБРОС ПАРОЛЯ",

      choose_one_treatment_schemes: "выберите одну из схем лечения",
      remember_your_email_password: "запомнить ваш email и пароль?",
      removed_string: "Удалено строк",
      return: "Вернуть",
      remove_nasovsev: "Удалить насовсем",
      recipe_successfully_signed_digital_signature: "Рецепт был успешно подписан цифровой подписью.",
      contact_administrator: "Обратитесь к администратору.",
      attention_doctor_not_specified: "Внимание! Врач не указан.",
      no_data: "нет данных",
      dosage_not_specified: "Дозировка не указана",
      recipe_discharged: "Рецепт выписан!",
      you_print_send_mail: "Теперь вы можете распечатать его, или отправить на почту.",
      appointment_sheet_formed: "Лист назначения сформирован!",
      //Лист назначения сформирован

      ready: "Готово",
      recipe_shipped_by: "Рецепт отправлен на",
      percent: "процент",
      error: "Ошибка!",
      protocol_request_error_occurred: "Возникла ошибка запроса протокола.",
      weekly: "еженедельно",

      mo: "Пн",
      tu: "Вт",
      we: "Ср",
      th: "Чт",
      fr: "Пт",
      sa: "Сб",
      su: "Вс",

      ivanov: "Иванов",
      ivan: "Иван",
      ivanovich: "Иванович",

      concentration: "концентрация",
      clinical_recommendations_short: "Клинреки",
      pharmacotherapy_patient: "Фармакотерапия пациента",
      course_duration: "Длительность курса",
      course_day: "День курса",
      method_reception: "Способ приема",
      // multiplicity_reception: "Кратность приема",
      form: "Форма",
      // dosage: "Дозировка",
      name: "Название",
      //comment_prescription
      сomment_leaf_destination: "Комментарий к листу назначения",
      sources: "Источники",

      date_commencement_therapy: "Дата начала терапии",
    },
  },
  en: {
    translation: {
      greetings: "Welcome",
      contact_administrator: "Please, contact the administrator.",
      attention_doctor_not_specified: "Attention! The doctor is not set.",
      report_an_error: "Report an error", /// //// ///
      notify_about_error: "Report an error",
      enter_message_text: "Enter message text",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("lang") || "ru",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.res = resources.ru.translation;

export default i18n;
