/* eslint-disable no-eval */
/**
 * Непонятно почему нельзя было вручную поделить, без eval, пока оставляем
 */
const clarifications = {
  //clarifications
  period: {
    everyDay: { id: "0A2UphMxR6CRLdcLZZP42w==" },
    permanently: { id: "2Hf/nn19JW1B3TZ+0FEULA==" },
    ofNecessity: { id: "y+ag+KJdSoP/WI7xb2p6pQ==" },
    once: { id: "1TNWvcRUJcY2wCAy+fx4iw==" },
    // once: { id: "qdy47ToSJg8XEjTWFPVWVQ==" },
  },

  //clarifications
  clarifications: {
    timeMorning: { id: "m+W4+ZXwBL+ST7J88Q14sQ==" },
    timeDinner: { id: "h6byVd0S+LVxd8+kMqJ3KA==" },
    timeEvening: { id: "0lDuva4iOJ0uHPQqBah+dQ==" },
  },
  // type: "value"
  // unit_1: "day"
  // unit_2: null
  // value_1: 2
  // value_2: null
  //count day
  presets: {
    perDay1: { id: "CrjuCSzHZJ4I6PSMAXzTpw==", tpl: [{ type: 'count', x: 1, timesx: 'days', multiplicate: 1 }] },
    perDay2: { id: "Ps9lIMgjH9YIahJr4v1aiQ==", tpl: [{ type: 'count', x: 2, timesx: 'days', multiplicate: 2 }] },
    perDay3: { id: "d0KWzXuvobA1wfOiLWyusA==", tpl: [{ type: 'count', x: 3, timesx: 'days', multiplicate: 3 }] },
    perDay4: { id: "WZ8i1CtiQavxPlOh3dRqYw==", tpl: [{ type: 'count', x: 4, timesx: 'days', multiplicate: 4 }] },
    perDay5: { id: "Ft9B9+D8Yr0lbepkIW5m9A==", tpl: [{ type: 'count', x: 5, timesx: 'days', multiplicate: 5 }] },

    //count week
    perWeek1: { id: "pdKXge8w/eC+6L635qb2YA==", tpl: [{ type: 'count', x: 1, timesx: 'weeks', multiplicate: eval(1 / 7) }] },
    perWeek2: { id: "AmkBNmf+thJ8dohSe+s+RA==", tpl: [{ type: 'count', x: 2, timesx: 'weeks', multiplicate: eval(2 / 7) }] },

    //every
    every30min: { id: "BJ6ybdRulIhypOEQtOpszQ==", tpl: [{ type: 'every', x: 30, timesx: 'minutes', multiplicate: 48 }] },
    every1hour: { id: "lDUWlUY8H+tNs+OKEpt6og==", tpl: [{ type: 'every', x: 1, timesx: 'hours', multiplicate: 24 }] },
    every2hours: { id: "Po6jgxEdiAPf1vHpdBzegA==", tpl: [{ type: 'every', x: 2, timesx: 'hours', multiplicate: 12 }] },
    every3hours: { id: "ZN4Ap9s8XKn01RWVlSpNmQ==", tpl: [{ type: 'every', x: 3, timesx: 'hours', multiplicate: 8 }] },
    every4hours: { id: "QaZjko/HrJx2RYSxfahsZg==", tpl: [{ type: 'every', x: 4, timesx: 'hours', multiplicate: 6 }] },

    //every
    onceIn2days: { id: "PC59kILvXj+gK1I513yvqA==", tpl: [{ type: 'every', x: 2, timesx: 'days', multiplicate: eval(1 / 2) }] },
    onceIn3days: { id: "wsZymWx36purT46zC9kJyA==", tpl: [{ type: 'every', x: 3, timesx: 'days', multiplicate: eval(1 / 3) }] },
    onceIn4days: { id: "HjM4QHQn8D6WrCf7on3AtA==", tpl: [{ type: 'every', x: 4, timesx: 'days', multiplicate: eval(1 / 4) }] },

    //every
    onceIn3months: { id: "tuOuBQ1wHSnBcZ8utQhA4g==", tpl: [{ type: 'every', x: 3, timesx: 'months', multiplicate: eval(1 / 90) }] },
  },


  durations: {
    day1: { id: "MCdgBksBJO2voZHSf+8z0Q==", tpl: [{ type: 'period', x: 1, timesx: 'days' }] },
    day2: { id: "55rf+bPPeuD5fdSYaG+iWQ==", tpl: [{ type: 'period', x: 2, timesx: 'days' }] },
    day3: { id: "Jwy4qdjtdVf0PjkWRlIhCA==", tpl: [{ type: 'period', x: 3, timesx: 'days' }] },
    day4: { id: "Vm2onoNvpZ4m/cOuqWcGqQ==", tpl: [{ type: 'period', x: 4, timesx: 'days' }] },
    day5: { id: "Yf45FLCXzMWs1dN5HYkWOQ==", tpl: [{ type: 'period', x: 5, timesx: 'days' }] },
    day6: { id: "FtRgFyqlUopUCId/uq/XXg==", tpl: [{ type: 'period', x: 6, timesx: 'days' }] },
    day7: { id: "9M6gI2DM+/u2COQl65sJ+g==", tpl: [{ type: 'period', x: 7, timesx: 'days' }] },
    day8: { id: "28ha4b+mXPHFQGbarKWTvA==", tpl: [{ type: 'period', x: 8, timesx: 'days' }] },
    day9: { id: "rkNCAMMHq2Rpxc7Dell4yw==", tpl: [{ type: 'period', x: 9, timesx: 'days' }] },
    day10: { id: "XUIfYVzJRk6kHhzl4WuRRw==", tpl: [{ type: 'period', x: 10, timesx: 'days' }] },

    // week1: { id: "xwNS1WrhnSzD0o50kOBUMQ==", tpl: [{ type: 'period', x: 1, timesx: 'weeks' }] },
    week2: { id: "DW0hG34FIJ8zwtAOm39N0Q==", tpl: [{ type: 'period', x: 2, timesx: 'weeks' }] },
    week3: { id: "qo4gSuaNzs8FtHmkGXbSbA==", tpl: [{ type: 'period', x: 3, timesx: 'weeks' }] },

    month1: { id: "AlozZskw52klIvitKUT0Cg==", tpl: [{ type: 'period', x: 1, timesx: 'months' }] },
    month2: { id: "zk7tu8PR6iyf1fn3Wl1i4g==", tpl: [{ type: 'period', x: 2, timesx: 'months' }] },
    month3: { id: "MrgmnZmlwLGjKG5xT2JTwA==", tpl: [{ type: 'period', x: 3, timesx: 'months' }] },
    month4: { id: "lf3neHOAkQFkkFbvBiK2dw==", tpl: [{ type: 'period', x: 4, timesx: 'months' }] },
    month5: { id: "0DlV1Azn8iapxjt9QEGdkg==", tpl: [{ type: 'period', x: 5, timesx: 'months' }] },
    month6: { id: "H4OFOjjB9NZt8sXZKbVtVg==", tpl: [{ type: 'period', x: 6, timesx: 'months' }] },

    year1: { id: "84bZhPwPiy/sicBokOiUQA==", tpl: [{ type: 'period', x: 1, timesx: 'years' }] },
    year1_5: { id: "wIf4HYcTAEwOGcH5ytVHVQ==", tpl: [{ type: 'period', x: 18, timesx: 'months' }] },
    year2: { id: "aWOOuZwNyEIU8sQx0kl+0w==", tpl: [{ type: 'period', x: 2, timesx: 'years' }] },

  },

  durationsAlt: {
    always: { id: "2EGGWxMaj1l/uy3xKU3/KQ==", tpl: [] },
    before_canceling: { id: "lQ9LkaNuaG/IgjrmfEhzOQ==", tpl: [] },
    everyday: { id: "0A2UphMxR6CRLdcLZZP42w==", tpl: [] },

  },

  durationPresets: {
    day1: { id: "MCdgBksBJO2voZHSf+8z0Q==", tpl: [{ type: 'period', x: 1, timesx: 'days' }] },
    day2: { id: "55rf+bPPeuD5fdSYaG+iWQ==", tpl: [{ type: 'period', x: 2, timesx: 'days' }] },
    day3: { id: "Jwy4qdjtdVf0PjkWRlIhCA==", tpl: [{ type: 'period', x: 3, timesx: 'days' }] },
    day4: { id: "Vm2onoNvpZ4m/cOuqWcGqQ==", tpl: [{ type: 'period', x: 4, timesx: 'days' }] },
    day5: { id: "Yf45FLCXzMWs1dN5HYkWOQ==", tpl: [{ type: 'period', x: 5, timesx: 'days' }] },
    day6: { id: "FtRgFyqlUopUCId/uq/XXg==", tpl: [{ type: 'period', x: 6, timesx: 'days' }] },
    day7: { id: "9M6gI2DM+/u2COQl65sJ+g==", tpl: [{ type: 'period', x: 7, timesx: 'days' }] },
    day8: { id: "28ha4b+mXPHFQGbarKWTvA==", tpl: [{ type: 'period', x: 8, timesx: 'days' }] },
    day9: { id: "rkNCAMMHq2Rpxc7Dell4yw==", tpl: [{ type: 'period', x: 9, timesx: 'days' }] },
    day10: { id: "XUIfYVzJRk6kHhzl4WuRRw==", tpl: [{ type: 'period', x: 10, timesx: 'days' }] },

    week1: { id: "xwNS1WrhnSzD0o50kOBUMQ==", tpl: [{ type: 'period', x: 1, timesx: 'weeks' }] },
    week2: { id: "DW0hG34FIJ8zwtAOm39N0Q==", tpl: [{ type: 'period', x: 2, timesx: 'weeks' }] },
    week3: { id: "qo4gSuaNzs8FtHmkGXbSbA==", tpl: [{ type: 'period', x: 3, timesx: 'weeks' }] },

    month1: { id: "AlozZskw52klIvitKUT0Cg==", tpl: [{ type: 'period', x: 1, timesx: 'months' }] },
    month2: { id: "zk7tu8PR6iyf1fn3Wl1i4g==", tpl: [{ type: 'period', x: 2, timesx: 'months' }] },
    month3: { id: "MrgmnZmlwLGjKG5xT2JTwA==", tpl: [{ type: 'period', x: 3, timesx: 'months' }] },
    month4: { id: "lf3neHOAkQFkkFbvBiK2dw==", tpl: [{ type: 'period', x: 4, timesx: 'months' }] },
    month5: { id: "0DlV1Azn8iapxjt9QEGdkg==", tpl: [{ type: 'period', x: 5, timesx: 'months' }] },
    month6: { id: "H4OFOjjB9NZt8sXZKbVtVg==", tpl: [{ type: 'period', x: 6, timesx: 'months' }] },

    year1: { id: "84bZhPwPiy/sicBokOiUQA==", tpl: [{ type: 'period', x: 1, timesx: 'years' }] },
    year1_5: { id: "wIf4HYcTAEwOGcH5ytVHVQ==", tpl: [{ type: 'period', x: 18, timesx: 'months' }] },
    year2: { id: "aWOOuZwNyEIU8sQx0kl+0w==", tpl: [{ type: 'period', x: 2, timesx: 'years' }] },

    // 0A2UphMxR6CRLdcLZZP42w==: "ежедневно"
    // 2EGGWxMaj1l/uy3xKU3/KQ==: "постоянно"
    // 2W4VwKPPqEzPMYTobWRGKA==: "от {x1} по {x2} день каждого месяца"
    // 87jOVmS2PcVAry9CDgS5cQ==: "до достижения клинического улучшения"
    // DJvJisAZvE2J3jT6Vfd1/w==: "{x} курса"
    // IJrVdTJiAQ2PT2Yu6cmglA==: "{x} {день/неделя/месяц/год}"
    // hOPJYqm2Ou7T1kpzNDp8wQ==: "однократно"
    // iVRdM0xUZPJ5x/236/pYPQ==: "с перерывами {x} дней"
    // lQ9LkaNuaG/IgjrmfEhzOQ==: "до отмены"
    // occo0TiGg3iFl7GDYiN+xw==: "с увеличением дозы в каждый {x} день"
    // tjbTpp/J25LF5UBfOncPOw==: "в течение {x} {мин/час/день/неделя/месяц/год}"
  }


  // "PD5MovOwcNgbjzm7XekKSg==": "в течение {x} минут",
  // "bHxT8QqjaYU8OcCTQOZVvA==": "в течение {x} часов",
  // "5fySwZYtwQXOcaMnK0hOxw==": "в течение {x} дней",
  //
  // "s5HNXcLj/YDAsfkQqlyvIA==": "через каждый {x} {час/день/неделя/месяц}",
  // "0+iXk+IznToF2k/Q3J+zCg==": "{x} раз в {y} {час/день/неделю/месяц}",
  // "HuHetbCXy8hSZPXLXntD9A==": "{x} раз в {час/день/неделю/месяц}",
  // "Q6jllycUscxHNK0CYHNAzg==": "спустя {x} {час/день/неделя/месяц}",
  // "edl4I2pgN5jabAZ57GnMtw==": "в интервалах радиотерапии",
  // "cYLetvt0mim6HAmxHPYMzA==": "в {x} приема",
  // "ucQU6SU5INO2dcttA1rQLQ==": "с интервалом в {x} {час/день/неделя/месяц}",
}
export default clarifications
