export default {
  key: "key",
  device_id: null,
  active_language: "ru",

  clinic_id: null,
  clinic_code: null,
  clinic_name: null,
  dep_id: null,
  dep_code: null,
  dep_name: null,
  doctor_id: null,
  doctor_code: null,
  doctor_name: null,

  patient: {
    id: null,
    key: null,
    email: null,
    name: null,
    fio: null,
    snils: null,
    miscode: null,
    misid: null,
    birthdate: null,
    sex: null,
    growth: null,
    weight: null,
    masIndex: null,
    squareIndex: null,
    disease: [],
    co_disease: [],
    allergy: [],
    labparams: [],
    pregnancy: false,
    gestation: null,
    trimester: null,
    lactation: false,
    driving: false,
    alcohol: false,
    genpassport_exist: false,
  },

  liquids: [],
  drugs: [],
  deletedDrugs: [],
  deletedLiquids: [],

  text: null,
  recipe_endtime: 0,
  recipe_endtimeitem: {},
  recipe_formid: "",
  recipe_text: "",
};
