export default {
  id: null,
  key: null,
  email: null,
  name: null,
  fio: null,
  snils: null,
  miscode: null,
  misid: null,
  birthdate: null,
  sex: null,
  growth: null,
  weight: null,
  masIndex: null,
  squareIndex: null,
  disease: [],
  co_disease: [],
  allergy: [],
  labparams: [],
  pregnancy: false,
  gestation: null,
  trimester: null,
  lactation: false,
  driving: false,
  alcohol: false,
  mutations: [],
  lastname: null,
  patronymic: null,
  firstname: null,
  policy_num: null,
  medcard_num: null,
  main_address: null,
  genpassport_exist: false,
};
