import { observable, action, autorun, makeObservable } from "mobx";
import { createBrowserHistory } from "history";
import { PRE_PATH } from "../lib/connect";
import Mapper from "url-mapper";
import _ from "lodash";

export default class Router {
  location = null;
  route = null;
  match = null;
  values = {};

  constructor(options) {
    makeObservable(this, {
      location: observable,
      route: observable,
      match: observable,
      values: observable,
      onLocation: action,
    });

    // this.params = observable.map({});
    this.options = {
      routes: {},
    };
    let routes = (options && options.routes) || {};
    _.keys(routes).forEach((key, i) => {
      let newKey = PRE_PATH + key;
      this.options.routes[newKey] = routes[key];
    });
    this.stores = options.stores || {};
    // options.stores.router = this
    this.history = createBrowserHistory({ initialEntries: [window.location.pathname] });
    this.mapper = Mapper();
    this.history.listen(this.onLocation.bind(this));
    this.onLocation(this.history.location);

    // autorun(() => {
    //   // if (!this.match) this.push('/');
    // });
    autorun(() => {
      // console.log('ROUTER',this.values);
      // console.log(this.values.protocol);
      // console.log(this.match.name);
      // if (
      //   this.values &&
      //   this.match.name == "protocol" &&
      //   this.values.protocol &&
      //   typeof this.values.protocol !== "undefined"
      // ) {
      //   console.log("HERE 1");
      //   this.stores.warnings.protocol(this.values.protocol);
      // }
      // if (
      //   this.values &&
      //   this.match.name == "resultchecking" &&
      //   this.values.protocol &&
      //   typeof this.values.protocol !== "undefined"
      // ) {
      //   console.log("HERE 2");
      //   this.stores.warnings.checkMisWarnings(this.values.protocol);
      // }
      // console.log(this.values);
      // console.log(this.match);
      if (this.values && this.values.miskey && typeof this.values.miskey !== "undefined") {
        if (this.match.disabled) {
          // console.log("HERE!");
          this.stores.actions.disabled = true;
          //this.stores.actions.miskey = this.values.miskey;
        }
      }

      if (this.values && this.values.page && this.values.page === "chosedv") {
        if (this.values.drug) {
          this.stores.drugs.drugsByFD(this.values.drug);
        }
      }

      // if(this.values && this.values.page) this.stores.actions.setCurrentPage(this.values.page)
    });
  }

  onLocation(location) {
    // console.log("YES");
    // let oldRoute = this.match;
    // let oldVals = this.values;
    let matchedRoute = this.mapper.map(location.pathname, this.options.routes);
    // console.log(matchedRoute);
    let { match, values, route } = matchedRoute;
    // console.log({ match });

    /**
     * @desc match.section и this.match.section во всех проверенных случаях либо пустые либо оба равны main
     * @==
     */
    if (this.match && match.section === this.match.section && match.section !== "auth") {
      Object.assign(this, { values, route, location });
      this.match.name = match.name;
      //this.match.disabled = match.disabled;
    } else {
      Object.assign(this, { match, values, route, location });
    }

    // if (this.match.onEnter) this.match.onEnter();
    // if (oldRoute.onExit) oldRoute.onExit();
    // if(oldRoute.name === 'project' && this.match.name === 'project'){
    //   this.stores.client.socket.setPair(this.pairId);
    // }
  }

  setValues(values) {
    let location = this.mapper.stringify(this.route, values);
    if (location !== this.location.pathname) this.push(location);
  }

  push(location) {
    if (this.location && this.location.pathname === location) return;
    this.history.push(PRE_PATH + location);
  }
  replace(location) {
    this.history.replace(location);
  }
  go(n) {
    this.history.go(n);
  }
  goBack() {
    this.history.goBack();
  }
  goForward() {
    this.history.goForward();
  }
  createHref() {
    return this.history.createHref(...arguments);
  }
}
