import { observable, autorun, makeObservable } from "mobx";
import { POST } from "../lib/connect";

import _ from "lodash";

class DrugInfo {
  ready = false;

  current = {
    //drugDosage
    drug: {},
    form: {},
    methodform: {},
    names: {},
    nameshort: {},
    units: {},
  };

  names = {};

  constructor({ stores }) {
    makeObservable(this, {
      ready: observable,
      current: observable,
      names: observable,
    });

    this.stores = stores;
    this.router = stores.router;

    this.info = observable.map({}, "analog");

    autorun(() => { });
  }

  /**
  // метод пполучения инструкций и доп инфо для драга
  * @param drug_obj - драг
  */
  async get(drug_obj) {
    this.ready = false;
    this.stores.actions.loading = true;
    let { active_language } = this.stores.actions;
    // let currentDosages = await this.dosage.get(drug_obj.key);
    // if(currentDosages) {
    //   await this.stores.drugs.getDosageParams()
    //   return
    // }
    let data = {
      drug: drug_obj,
      active_language,
    };
    data.authkey = this.stores.actions.authkey;
    data.key = this.stores.actions.privateKey;
    data.drug.helpers = null;
    return POST({
      path: `drugs/Info`,
      data,
      success: async (data) => {
        // console.log('DATA', data);
        let { сalculation, instruction } = data;

        // this.names = await _.extend(this.names, names)

        let current = (await this.info.get(drug_obj.key)) || {};

        let info = {
          key: drug_obj.key,
          id: drug_obj.id,
          itemsCalc: сalculation,
          itemsInstr: instruction,
        };

        await this.info.set(drug_obj.key, _.extend(current, info));

        // await tn.forEach(( analogObj ) => {
        //   let analog = {
        //     id: analogObj.drug.id,
        //     drug: analogObj.drug,
        //     name: analogObj.drug.name,
        //     arr: [],
        //     min: null,
        //     max: null
        //   }
        //   analogObj.fp && _.forEach(analogObj.fp, ( item, id ) => {
        //     if( item.pricemin && item.pricemin > analog.min ) analog.min =item.pricemin
        //     if( item.pricemax && item.pricemax > analog.max ) analog.max =item.pricemax
        //     analog.arr.push({
        //       id: id,
        //       name: names[id],
        //       min: item.pricemin,
        //       max: item.pricemax
        //     })
        //   })
        //   analogs.arr.push(analog)
        // })
        // await this.analog.set( drug_obj.key, _.extend(current, analogs) );
        this.ready = true;
        this.stores.actions.loading = false;
        // this.stores.tools.setNames(names, nameshort)
        // let current = this.dosage.get(drug.key) || {};
        // this.dosage.set( drug.key, _.extend(current, { drug, form, methodform, names, nameshort, units, url: '/concept/dozes/' }) );
        // if(units && units.length > 0) this.stores.tools.getUnitsPharm(units)
      },
      fail: (alerts) => {
        this.stores.actions.loading = false;
      },
      always: (data) => {
        this.stores.actions.loading = false;
        this.stores.actions.makeAlerts(data);
      },
    });
  }
}
export default DrugInfo;
