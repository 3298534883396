export default {
  id: null,
  fio: null,
  firstname: null,
  lastname: null,
  patronymic: null,
  sex: null,
  snils: null,
  diploms: [],
  licenses: [],
  emails: [],
  phone: [],
};
